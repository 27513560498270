import React, { useState, useEffect } from 'react';
import lineups from './lineups.json';
import './App.css';
import LazyLoad from 'react-lazyload';
function App() {
  const [agent, setAgent] = useState('');
  const [map, setMap] = useState('');
  const [site, setSite] = useState('');
  const [side, setSide] = useState('');
  const [ability, setAbility] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const hasFilter =
    agent !== '' || map !== '' || site !== '' || side !== '' || ability !== '';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLineup, setSelectedLineup] = useState(null);

  // Extract unique values for each selector
  const agents = [...new Set(lineups.map((item) => item.agent))];
  const maps = [...new Set(lineups.map((item) => item.map))];
  const sites = [...new Set(lineups.map((item) => item.site))];
  const sides = [...new Set(lineups.map((item) => item.side))];
  const abilities = [...new Set(lineups.map((item) => item.ability))];

  const filteredLineups = lineups.filter((lineup) => {
    return (
      (agent === '' || lineup.agent === agent) &&
      (map === '' || lineup.map === map) &&
      (site === '' || lineup.site === site) &&
      (side === '' || lineup.side === side) &&
      (ability === '' || lineup.ability === ability)
    );
  });
  const displayedLineups = hasFilter ? filteredLineups : [];

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLineups = displayedLineups.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(displayedLineups.length / itemsPerPage);

  const openModal = (lineup) => {
    setSelectedLineup(lineup);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedLineup(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isModalOpen) {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isModalOpen]);

  const clearFilters = () => {
    setAgent('');
    setMap('');
    setSite('');
    setSide('');
    setAbility('');
    setCurrentPage(1);
  };

  return (
    <div className="App">
      <h1 onClick={clearFilters}>Valorant Lineups</h1>

      <div className="selectors">
        {/* Agent Selector */}
        <select value={agent} onChange={(e) => setAgent(e.target.value)}>
          <option value="">All Agents</option>
          {agents.map((agentName) => (
            <option value={agentName} key={agentName}>
              {agentName.charAt(0).toUpperCase() + agentName.slice(1)}
            </option>
          ))}
        </select>

        {/* Map Selector */}
        <select value={map} onChange={(e) => setMap(e.target.value)}>
          <option value="">All Maps</option>
          {maps.map((mapName) => (
            <option value={mapName} key={mapName}>
              {mapName.charAt(0).toUpperCase() + mapName.slice(1)}
            </option>
          ))}
        </select>

        {/* Site Selector */}
        <select value={site} onChange={(e) => setSite(e.target.value)}>
          <option value="">All Sites</option>
          {sites.map((siteName) => (
            <option value={siteName} key={siteName}>
              {siteName.toUpperCase()}
            </option>
          ))}
        </select>

        {/* Side Selector */}
        <select value={side} onChange={(e) => setSide(e.target.value)}>
          <option value="">All Sides</option>
          {sides.map((sideName) => (
            <option value={sideName} key={sideName}>
              {sideName.charAt(0).toUpperCase() + sideName.slice(1)}
            </option>
          ))}
        </select>

        {/* Ability Selector */}
        <select value={ability} onChange={(e) => setAbility(e.target.value)}>
          <option value="">All Abilities</option>
          {abilities.map((abilityName) => (
            <option value={abilityName} key={abilityName}>
              {abilityName.charAt(0).toUpperCase() + abilityName.slice(1)}
            </option>
          ))}
        </select>
      </div>

      <div className={`lineups ${currentLineups.length === 0 ? 'no-lineups-container' : ''}`}>
        {currentLineups.length > 0 ? (
          currentLineups.map((lineup, index) => (
            <div className="lineup-item" key={index}>
              <LazyLoad height={200} offset={100}>
                <img
                  src={lineup.image}
                  alt={`${lineup.agent} lineup`}
                  onClick={() => openModal(lineup)}
                />
              </LazyLoad>
              <p>
                Agent: {lineup.agent.charAt(0).toUpperCase() + lineup.agent.slice(1)} | Map:{' '}
                {lineup.map.charAt(0).toUpperCase() + lineup.map.slice(1)} | Site:{' '}
                {lineup.site.toUpperCase()} | Side:{' '}
                {lineup.side.charAt(0).toUpperCase() + lineup.side.slice(1)} | Ability:{' '}
                {lineup.ability.charAt(0).toUpperCase() + lineup.ability.slice(1)}
              </p>
            </div>
          ))
        ) : (
          <p className="no-lineups">
            {hasFilter
              ? 'No lineups found for the selected filters.'
              : 'Please select filters to view lineups.'}
          </p>
        )}
      </div>
      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number + 1}
              onClick={() => setCurrentPage(number + 1)}
              className={currentPage === number + 1 ? 'active' : ''}
            >
              {number + 1}
            </button>
          ))}
        </div>
      )}
      {/* Modal */}
      {isModalOpen && selectedLineup && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>
            <img src={selectedLineup.image} alt="Expanded lineup" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
